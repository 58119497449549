<template>
  <div class="terms_common">
    <h4>램 개인정보처리방침</h4>

    <!---->
    <p>
      리뷰앤메이크머니 (주)-약칭 '램'-는 [개인정보 보호법] 등 관련 법령의 지침을
      준수합니다. 당사의 개인정보처리방침은 관련 법령을 따른 것입니다. 당사의
      플랫폼 [리뷰하고 돈벌자]에 적용되는 개인정보처리방침은 다음과 같습니다.
    </p>
    <p>
      <br />

      <!---->
    </p>

    <h6>1. 개인정보 수집</h6>
    <br />
    <p>
      회원가입을 하지 않은 이용자도 본 플랫폼 [리뷰하고 돈벌자]에서 리뷰읽기,
      정보검색 등의 기본 서비스를 일반회원과 동일하게 이용할 수 있습니다. 그러나
      이용자가 상품/서비스 구매나 찜한 리뷰읽기 등 더 다양한 서비스를 이용하기
      위해서는 일반회원으로 가입해야 합니다. 아울러 일반회원이 리뷰어 채널이나
      판매자 채널 등 본 플랫폼이 제공하는 또 다른 서비스를 이용하려 할 경우,
      해당 서비스에 필요한 등록 절차를 거쳐야하며, 각 단계마다 램이 요청하는
      개인정보를 제공해야 합니다. 리뷰앤메이크머니(주)가 이용자로부터 수집하는
      개인정보는 오직 해당 목적을 위해서만 사용되며 관련 법령에 따라 관리됩니
      다. 램이 수집하는 개인정보는 다음과 같습니다.
    </p>
    <p><br /></p>

    <p>① 일반회원가입 시</p>
    <p></p>
    <p class="dot">
      필수: 신청자의 램 아이디, 비밀번호, 이메일 주소, 이름, 닉네임,
      휴대전화번호
    </p>
    <p></p>
    <p class="dot">선택: 신청자의 생년월일, 성별</p>
    <p></p>
    <p>
      ② 일반회원이 [설정]에서 개인정보를 변경하거나 프로필 사진을 등록/변경 할
      경우
    </p>
    <p></p>
    <p class="dot">
      회원의 프로필 사진/아이콘, 닉네임, 휴대전화번호, 이메일 주소, 비밀번호
    </p>
    <p></p>
    <p>③ 일반회원이 상품/서비스를 구매 할 경우</p>
    <p></p>
    <p class="dot">필수: 배송지 정보(이름, 주소, 전화번호)</p>
    <p></p>
    <p>④ 본인인증 시</p>
    <p></p>
    <p class="dot">
      필수: 본인확인정보(이름, 생년월일, 성별, 내/외국인 여부, 휴대전화번호,
      이동통신사정보, 본인 확인값(CI/DI)
    </p>
    <p></p>
    <p>⑤ 제휴사 신청의 경우</p>
    <p></p>
    <p class="dot">필수: 회사명, 담당자명, 이메일, 전화번호</p>
    <p></p>
    <p class="dot">선택: 회사 홈페이지 주소</p>
    <p><br /></p>

    <p>
      [리뷰어 채널 등록]이나 [판매자 채널 등록] 등등 추가로 개인정보를 수집할
      경우 회사는 해당 단계마다 이용자의 동의를 받습니다. 서비스 이용 과정에서
      IP 주소, 쿠키, 서비스 이용기록, 방문 일시, 기기 정보 등이 생성되어 수집될
      수 있습니다. 이는 보다 정확한 서비스 제공을 위한 것이며, 관련 법령에 따라
      보호됩니다.
    </p>
    <p>
      <br />

      <!---->
    </p>

    <h6>2. 수집된 개인정보 이용</h6>
    <br />
    <p>
      회사는 당사가 제공하는 플랫폼에서의 회원 관리, 서비스의 제공 및 개선/개발,
      안전한 인터넷이용환경 구축 등등 아래의 목적을 위해 수집된 개인정보를
      이용합니다.
    </p>
    <p><br /></p>
    <p class="dot">
      회원식별, 회원의 가입 의사 확인, 본인 연령 확인, 회원 탈퇴 의사 확인
    </p>
    <p></p>
    <p class="dot">
      부정 이용 방지 및 제재, 고지사항 전달, 분쟁 조정을 위한 기록 보존, 민원
      처리
    </p>
    <p></p>
    <p class="dot">
      상품/서비스 거래에 따른 본인인증, 구매 및 요금 결제, 상품/서비스의 배송
    </p>
    <p></p>
    <p class="dot">
      찜, 메시지/알림, 댓글 쓰기, 내가 쓴 댓글 읽기, 평점 주기, SNS를 통한 정보
      공유하기 등등 플랫폼의 기능 작동
    </p>
    <p></p>
    <p class="dot">각종 평점 주기에 대한 포인트 지급</p>
    <p></p>
    <p class="dot">
      성별/연령별 통계학적 분석, 맞춤형 서비스 개발/제공 등을 위한 서비스
      이용기록 및 접속 빈도 분석
    </p>
    <p></p>
    <p class="dot">마케팅, 프로모션, 광고 등을 위한 데이터 분석</p>
    <p></p>
    <p class="dot">보안, 안전, 사생활 보호 등을 위한 서비스 이용환경 구축</p>
    <p>
      <br />

      <!---->
    </p>

    <h6>3. 수집된 개인정보 보관 기간</h6>
    <br />
    <p>
      회원의 개인정보는 [개인정보 보호법] 제21조와 회사의 개인정보처리방침에
      따라 아래의 해당 기간이 경과한 후 지체없이, 복구가 불가능한 방식으로
      파기됩니다.
    </p>
    <p><br /></p>

    <p>[램의 개인정보처리 규정]</p>
    <p></p>
    <p>
      1. 부정거래 방지 및 금융사고 조사 등의 목적을 위해 회원 정보 : 6개월 보관
    </p>
    <p></p>
    <p>
      2. 부정가입 등의 부정이용기록(가입인증 휴대전화번호 또는 DI) : 회원탈퇴 후
      6개월 보관
    </p>
    <p></p>
    <p>3. 결제도용 등의 부정거래기록 : 3년 보관</p>
    <p><br /></p>
    <p>[전자상거래 등에서의 소비자보호에 관한 법률]</p>
    <p></p>
    <p>1. 계약 또는 청약 철회 등에 관한 기록 : 5년 보관</p>
    <p></p>
    <p>2. 대금결제 및 재화 등의 공급에 관한 기록 : 5년 보관</p>
    <p></p>
    <p>3. 소비자의 불만 또는 분쟁 처리에 관한 기록 : 3년 보관</p>
    <p></p>
    <p>4. 표시·광고에 관한 기록 : 6개월 보관</p>
    <p><br /></p>
    <p>[국세기본법]</p>
    <p></p>
    <p>1. 각 세법이 규정하는 거래에 관한 장부 및 증빙서류 모두 : 5년 보관</p>
    <p><br /></p>
    <p>[전자금융거래법]</p>
    <p></p>
    <p>1. 전자금융에 관한 기록: 5년 보관</p>
    <p><br /></p>
    <p>[전자문서 및 전자거래 기본법]</p>
    <p></p>
    <p>
      1. 인증서와 인증 업무에 관한 기록 : 인증서 효력 상실일로부터 10년 보관
    </p>
    <p><br /></p>
    <p>[통신비밀보호법]</p>
    <p></p>
    <p>
      1. 로그인 기록을 포함한 통신사실 확인자료([통신비밀보호법] 제2조 제11항에
      규정된 자료들) : 3개월 보관
    </p>
    <p><br /></p>
    <p>[개인정보 보호법]</p>
    <p></p>
    <p>
      1. 램 서비스를 1년 동안 이용하지 않은 이용자의 개인정보 : 즉시 파기 또는
      관련 법령 및 회사의 개인정보처리방침에 해당하는 정보 분리 보관(파기 또는
      분리 보관 1개월 전 해당 회원에게 이메일 통보)
    </p>
    <br />

    <!---->
    <h6>4. 개인정보의 제3자 제공</h6>
    <br />
    <p>
      회사는 회사가 회원에게 제공하는 서비스를 시행하기 위한 목적 외에 다른
      용도로 회원의 개인정보를 이용하거나 제3자에게 제공하지 않습니다. 다만,
      회사는 서비스의 원활한 제공을 위해 개인정보보호법 제17조에 따라 회원의
      동의를 받은 경우 또는 개인정보보호법 제18조에 따라 다른 법령이 규정하는
      사안에 해당하는 경우 회원의 개인정보를 제3자에게 제공할 수 있습니다.
    </p>
    <br />

    <!---->
    <h6>5. 개인정보의 처리 위탁</h6>
    <br />
    <p>
      회사는 회사가 제공하는 서비스의 보다 나은 시행을 위해 아래의 경우 개인정보
      처리업무를 위탁하고 있습니다.
    </p>
    <table summary="">
      <colgroup>
        <col width="40%" />
        <col width="*" />
      </colgroup>
      <thead>
        <tr>
          <th>수탁자</th>
          <th>취급업무</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>주식회사 가비아</td>
          <td>호스팅 제공 및 데이터 보관</td>
        </tr>
        <tr>
          <td>토스페이먼츠(주)</td>
          <td rowspan="2">결제 대행</td>
        </tr>
        <tr>
          <td>페이코, 카카오페이, 토스페이</td>
        </tr>
        <tr>
          <td>(주)굿스플로</td>
          <td>상품 배송</td>
        </tr>
        <tr>
          <td>가비아씨엔에스(주)</td>
          <td>SMS 및 이메일 발송</td>
        </tr>
        <tr>
          <td>써머스플랫폼(주)</td>
          <td rowspan="2">카카오톡 및 알림톡 발송</td>
        </tr>
        <tr>
          <td>가비아씨엔에스(주)</td>
        </tr>
      </tbody>
    </table>
    <br />

    <!---->
    <h6>6. 정보주체와 법정대리인의 권리․의무 및 그 행사방법</h6>
    <br />
    <p>
      램 회원은 자신의 개인정보 처리와 관련해 다음의 권리를 갖습니다(램은 14세
      미만 아동의 램 회원가입을 금지하나, 일련의 행위를 통해 해당 아동이 램에
      개인정보를 제공하는 경우 해당 아동에 대한 권리는 법정대리인이 갖습니다).
    </p>
    <br />
    <p class="dot">
      자신의 개인정보 처리에 대해 동의 또는 거부할 수 있는 권리, 동의의 범위를
      선택할 수 있는 권리(단, 램이 필요로 하는 최소한의 개인정보 수집을 거부할
      경우, 램이 제공하는 서비스의 이용이 제한될 수 있습니다.)
    </p>
    <p class="dot">
      자신이 제공한 개인정보를 정정하거나 램의 개인정보 이용내역을 열람할 권리,
      램의 개인정보 이용을 정지하거나 램의 개인정보 수집 이용에 대한 동의를
      철회함으로써 자신의 개인정보를 삭제, 파기할 권리(단, 이 경우 해당
      개인정보에 의해 제공되는 램 서비스의 이용은 불가능합니다.)
    </p>
    <p class="dot">
      개인정보의 처리 과정에서 발생하는 피해를 신속하고 공정한 절차에 따라
      구제받을 권리
    </p>
    <p class="dot">개인정보의 처리에 관한 정보를 제공받을 권리</p>
    <p class="dot">
      위의 사항들을 대리인에게 위임할 수 있는 권리(이 경우 [개인정보 처리 방법에
      대한 고시(제2020-7호)] 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다)
    </p>
    <br />

    <!---->
    <h6>7. 개인정보의 파기절차 및 파기방법</h6>
    <br />
    <p class="dot">
      램은 수집된 개인정보가 수집 목적에 따라 이용 완료되었거나 보관 기간이
      경과한 경우 해당 개인정보를 지체없이 복구가 불가능한 방식으로 파기합니다.
    </p>
    <p class="dot">
      회원탈퇴 등 램 회원의 개인정보가 파기되어야 할 경우라도 위의 [3. 수집된
      개인정보 보관기간]에 해당하는 개인정보는 관련 법령과 램의 개인정보처리
      규정에 따라 해당 기간 분리, 보관, 관리됩니다.
    </p>
    <p class="dot">
      램은 개인정보 보호책임자의 관리와 승인을 받아 파기 사유가 발생한
      개인정보를 선정, 파기합니다.
    </p>
    <p class="dot">
      램은 전자적 파일 형태의 정보는 재생, 복구가 불가능한 기술적 방법으로, 종이
      형태의 정보는 분쇄기나 소각을 통해 파기합니다.
    </p>
    <br />

    <!---->
    <h6>8. 개인정보 보호책임자</h6>
    <br />
    <p>개인정보 보호책임자 : 이상호</p>
    <p>이메일 : legasa@reviewnmakemoney.com</p>
    <br />

    <!---->
    <p>
      당사의 플랫폼 [리뷰하고 돈벌자]의 이용자는 회원가입 시 요구되는 개인정보의
      수집 및 이용을 거부 할 권리가 있습니다. 그러나 거부할 경우 회원가입이
      불가능합니다. 리뷰앤메이크머니(주)는 회원의 개인정보보호를 위해 최선을
      다합니다.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
